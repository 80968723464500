import Vue            from 'vue'
import apiService     from '@/services/api.js';
import i18n           from '@/lang';

const model = '/sites';
const endpoints = {
  all:              model+'/all',
  get:              model+'/get',
  insert:           model+'/insert',
  update:           model+'/update',
  updateFavicon:    model+'/update/favicon',
  updateBranding:   model+'/update/branding',
  updateReport:    model+'/update/report',
  riskScore:        model+'/risk-score',
  delete:           model+'/delete',
  toggle:           model+'/toggle',
}
const mutation_calls = {
  all:              'SET_ALL',
  get:              'SET_GET',
  insert:           'SET_INSERT',
  update:           'SET_UPDATE',
  updateFavicon:    'SET_UPDATE_FAVICON',
  updateBranding:   'SET_UPDATE_BRANDING',
  updateReport:     'SET_UPDATE_REPORT',
  riskScore:        'SET_RISK_SCORE',
  delete:           'SET_DELETE',
  toggle:           'SET_TOGGLE',
}

const state = () => ({
  all: [],
  get: null,
})

// getters
const getters = {
  all: state => state.all,
  get: state => state.get,
}

// actions
const actions = {
  all(context) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      let clients = context.getters['all'];
      if(clients.length > 0){
        return resolve();
      }
      apiService.post(endpoints.all, {
        'user_id': user.id
      })
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.all, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("sites.all.status.error"), { root: true })
        reject(error);
      });
    });
  },
  allWithReportFlag(context, fields) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.all, {
        'month': fields.month,
        'user_id': user.id,
        'report_flag': 'true'
      })
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.all, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("sites.all.status.error"), { root: true })
        reject(error);
      });
    });
  },
  async get(context, fields) {
    return new Promise((resolve, reject) => {
      if(fields.id == 0){
        return resolve();
      }
      let apiParams = { 
        'id': fields.id
      };
      apiService.post(endpoints.get, apiParams)
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.get, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("sites.all.status.error"), { root: true })
        reject(error);
      });
    });
  },
  insert(context, fields) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.insert, {
        'name': fields.name,
        'url': fields.url.url.startsWith('http') ? fields.url : `https://${fields.url}`,
        'frequency': fields.frequency,
        'framework': fields.framework,
        'host': fields.host,
        'started_on': fields.started_on,
        'user_id': user.id,
        'recipients': fields.recipients
      })
      .then(response => {
        let payload = response.data[0];
        context.commit(mutation_calls.insert, payload);
        context.dispatch('system/setMessage', i18n.t("sites.insert.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("sites.insert.status.error"), { root: true })
        reject(error);
      });
    });
  },
  update(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.update, {
        'name': fields.name,
        'frequency': fields.frequency,
        'framework': fields.framework,
        'host': fields.host,
        'started_on': fields.started_on,
        'id': fields.id,
        'recipients': fields.recipients
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.update, fields);
        context.dispatch('system/setMessage', i18n.t("sites.update.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("sites.update.status.error"), { root: true })
        reject(error);
      });
    });
  },
  updateRiskScore(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.riskScore, {
        'score': fields.score,
        'id': fields.id
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.riskScore, fields);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("sites.update.status.error"), { root: true })
        reject(error);
      });
    });
  },
  updateFavicon(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.updateFavicon, {
        'id': fields.id
      })
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.updateFavicon, payload);
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("sites.update.status.error"), { root: true })
        reject(error);
      });
    });
  },
  updateBranding(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.updateBranding, {
        'override_branding': fields.override_branding,
        'id': fields.id
      })
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.updateBranding, payload);
        context.dispatch('system/setMessage', i18n.t("meta.update.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("meta.update.status.error"), { root: true })
        reject(error);
      });
    });
  },
  updateReport(context, fields) {
    return new Promise((resolve, reject) => {
      apiService.post(endpoints.updateReport, {
        'reports': fields.reports_list,
        'id': fields.id
      })
      .then(response => {
        let payload = response.data;
        context.commit(mutation_calls.updateReport, fields);
        context.dispatch('system/setMessage', i18n.t("meta.update.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("meta.update.status.error"), { root: true })
        reject(error);
      });
    });
  },
  delete(context, fields) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.delete, { 
        'id': fields.id,
        'user_id': user.id
      })
      .then(response => {
        let payload = response;
        context.commit(mutation_calls.delete, fields.index);
        context.dispatch('system/setMessage', i18n.t("sites.delete.status.success"), { root: true })
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("sites.delete.status.error"), { root: true })
        reject(error);
      });
    });
  },
  toggle(context, fields) {
    return new Promise((resolve, reject) => {
      let user = context.rootGetters['users/user'];
      apiService.post(endpoints.toggle, {
        'id': fields.id,
        'user_id': user.id
      })
      .then(response => {
        let payload = response.data[0];
        let active = payload.active;
        context.commit(mutation_calls.toggle, {
          id: fields.id,
          active: active
        });
        if(active == 1){
          context.dispatch('system/setMessage', i18n.t("sites.toggle.status.activated"), { root: true })
        }else{
          context.dispatch('system/setMessage', i18n.t("sites.toggle.status.deactivated"), { root: true })
        }
        resolve(payload);
      })
      .catch(error => {
        context.dispatch('system/setMessage', i18n.t("sites.toggle.status.error"), { root: true })
        reject(error);
      });
    });
  },
}

// mutations
const mutations = {
  SET_ALL(state, payload) {
    state.all = payload.sort((t1,t2) => t1.name < t2.name ? -1 : 1);
    state.all = state.all.map(site => {
      site.gtm_loading = false;
      return site;
    })
  },  
  SET_GET(state, payload) {
    state.get = payload;
  },
  SET_INSERT(state, payload) {
    state.all.push(payload);
  },
  SET_UPDATE(state, payload) {
    let index = state.all.findIndex(site => site.id == payload.id);
    Vue.set(state.all, index, {...payload})
  },
  SET_RISK_SCORE(state, payload) {
    state.all = state.all.map(site => {
      if (site.id === payload.id) {
        site.risk_score = payload.score;
      }
      return site;
    })
  },
  SET_UPDATE_FAVICON(state, payload) {
    state.all = state.all.map(site => {
      if (site.id === payload.id) {
        site.favicon = payload.favicon;
      }
      return site;
    })
  },
  SET_UPDATE_BRANDING(state, payload) {
    state.all = state.all.map(site => {
      if (site.id === payload[1]) {
        site.override_branding = payload[0];
      }
      return site;
    })
  },
  SET_UPDATE_REPORT(state, payload) {
    state.all = state.all.map(site => {
      if (site.id === payload.id) {
        site.reports_list = payload.reports_list;
      }
      return site;
    })
  },
  SET_DELETE(state, payload) {
    state.all.splice(payload, 1);
  },
  SET_TOGGLE(state, payload) {
    state.all = state.all.map(site => {
      if (site.id === payload.id) {
        site.active = payload.active;
      }
      return site;
    })
  },
  SET_WPEXISTS(state, payload) {
    state.all = state.all.map(site => {
      if (site.id === payload.client_id) {
        site.wp_exists = payload.wp_exists;
      }
      return site;
    })
  },  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}